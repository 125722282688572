import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import userInfo from './modules/setUserInfo';
import studyList from './modules/study';

Vue.use(Vuex)

export default new Vuex.Store({
 modules:{
   userInfo,
   studyList
 },
 plugins: [createPersistedState()],
})