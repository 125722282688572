

export default {
    state: {
      studyList:{
        secondId:246
      },
      questionSwipeIndex:{
        index:-1,
        isShow:false
      },
      answerTime:{
        times:''
      },
      resolution_method:{
        key:'header_res'        // header_res 头部解析  answer_res 答题报告解析
      }
    },
    getters: {
      getStudyList:function(state){
         if(!state.studyList){
             state.studyList = JSON.parse(localStorage.getItem('studyList'));
         }
         return state.studyList;
      },
      getResolution_method:function(state){
        if(!state.resolution_method){
            state.resolution_method = JSON.parse(localStorage.getItem('resolution_method'));
        }
        return state.resolution_method;
     },
      getAnswerTime:function(state){
        if(!state.answerTime){
          state.answerTime = JSON.parse(localStorage.getItem('answerTime'));
        }
        return state.answerTime;
     },
      getQuestionSwipeIndex:function(state){
        if(!state.questionSwipeIndex){
            state.questionSwipeIndex = JSON.parse(localStorage.getItem('questionSwipeIndex'));
        }
        return state.questionSwipeIndex;
     }
    },
    mutations: {
      setAnswerTime(state,res){
        state.answerTime= res;
        localStorage.setItem('answerTime',JSON.stringify(res));
      },
      setResolution_method(state,res){
        state.resolution_method= res;
        localStorage.setItem('resolution_method',JSON.stringify(res));
      },
      setStudyList(state,res){
        state.studyList= res;
        localStorage.setItem('studyList',JSON.stringify(res));
      },
      setQuestionSwipeIndex(state,res){
        state.questionSwipeIndex= res;
        localStorage.setItem('questionSwipeIndex',JSON.stringify(res));
      }
    }
  } 