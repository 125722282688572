export default {
  state: {
    path:'/',//路径
    userInfo:{},//登录人信息
    firstTypesList:[],// 一级分类
    todos:{},
  },
  mutations: {
    setpath(state,prames){
      state.path = prames.path
    },
    setuserInfo(state,prames){
      state.userInfo = prames.userInfo  
    },
    setfirstTypesList(state,prames){
      state.firstTypesList = prames.firstTypesList
    },
    setTodos(state,prames){
      state.todos = prames.todos
    },
  },
  
  getters: {
    getUserInfo: state => {
      return state.userInfo
    }
  }
}
