<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
export default {
  created () {
    this.pathes = this.$route.path
    // vuex 保存数据刷新不丢失
    // var store = require('./store');
    //在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem("storedata") ) {
        this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(localStorage.getItem("storedata"))))
    }
    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload",()=>{
        localStorage.setItem("storedata",JSON.stringify(this.$store.state))
    });
    // 兼容iphone手机
    window.addEventListener("pagehide",()=>{
        localStorage.setItem("storedata",JSON.stringify(this.$store.state))
    });
    
  },
  methods:{
    
  }
}
</script>

<style>
#app {
  /* font-family: Noto Sans SC, Avenir, Helvetica, Arial, sans-serif; */
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-style: normal;
  font-weight: 400;
}
*{
  margin: 0;
  padding: 0;
}
</style>
