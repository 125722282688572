import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { Message } from 'element-ui' // 引用element-ui的加载和消息提示组件

Vue.use(VueRouter)
//避免跳转相同路径出错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue'),
    meta: {
      keepAlive: true,
      title: '萨恩课堂'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      title: '登陆-萨恩课堂'
    }
  },
  {
    path: '/appdetail',
    name: 'appdetail',
    component: () => import('../views/appdetail/index.vue'),
    meta: {
      title: '下载app-萨恩课堂'
    }
  },
  {

    path: '/liveVideo',
    name: 'liveVideo',
    component: () => import('../views/liveVideo/index.vue'),
  },
  {
    path: '/videoPlay',
    name: 'videoPlay',
    component: () => import('../views/videoPlay/index.vue'),
    meta: {
      title: '直播回放-萨恩课堂'
    }
  },
  {
    path: '/videoPlayRoom',
    name: 'videoPlayRoom',
    component: () => import('../views/videoPlay/main.vue'),
    meta: {
      title: '直播回放-萨恩课堂'
    }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/videoPlay/test.vue'),
    meta: {
      title: '直播回放-萨恩课堂'
    }
  },
  {
    path: '/collectPage',
    name: 'collectPage',
    component: () => import('../views/collectPage/index.vue')
  },
  {
    path: '/searchpage',
    name: 'searchpage',
    component: () => import('../views/searchpage/index.vue'),
    meta: {
      title: '搜索结果-萨恩课堂'
    }
  },
  {
    path: '/testReport',
    name: 'testReport',
    component: () => import('../views/testReport/index.vue')
  },
  {
    path: '/tikuReport',
    name: 'tikuReport',
    component: () => import('../views/tikuReport/index.vue')
  },
  {
    path: '/tikuAnalysis',
    name: 'tikuAnalysis',
    component: () => import('../views/tikuAnalysis/index.vue')
  },
  {
    path: '/testError',
    name: 'testError',
    component: () => import('../views/testError/index.vue'),
    meta: {
      title: '错题本-萨恩课堂'
    }
  },
  {
    path: '/analysisPage',
    name: 'analysisPage',
    component: () => import('../views/analysisPage/index.vue')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('../views/course/index.vue'),
    meta: {
      title: '选课中心-萨恩课堂'
    }
  },
  {
    path: '/coursedetail',
    name: 'coursedetail',
    component: () => import('../views/coursedetail/index.vue'),
  },
  {
    path: '/vipOrder',
    name: 'vipOrder',
    component: () => import('../views/vipOrder/index.vue'),
    meta: {
      title: '订单支付-萨恩课堂'
    }
  },
  {
    path: '/examination',
    name: 'examination',
    component: () => import('../views/examination/index.vue'),
    meta: {
      title: '在线题库-萨恩课堂'
    }
  },
  {
    path: '/tikuDetail',
    name: 'tikuDetail',
    component: () => import('../views/tiku/index.vue'),
    meta: {
      title: '在线题库-萨恩课堂'
    }
  },
  {
    path: '/tiku',
    name: 'tiku',
    component: () => import('../views/tiku/examination/index.vue'),
    meta: {
      title: '在线题库-萨恩课堂'
    }
  },
  {
    path: '/mnjktiku',
    name: 'mnjktiku',
    component: () => import('../views/computerExam/index.vue'),
    meta: {
      title: '在线题库-萨恩课堂'
    }
  },
  {
    path: '/mnlogin',
    name: 'mnlogin',
    component: () => import('../views/mn/mnlogin.vue'),
    meta: {
      title: '在线题库-萨恩课堂'
    }
  },
  {
    path: '/mndetail',
    name: 'mndetail',
    component: () => import('../views/mn/mndetail.vue'),
    meta: {
      title: '在线题库-萨恩课堂'
    }
  },
  {
    path: '/tiku_examcenter',
    name: 'tiku_examcenter',
    component: () => import('../views/tiku/examcenter/index.vue')
  },
  {
    path: '/examcenter',
    name: 'examcenter',
    component: () => import('../views/tiku/examcenter/index.vue')
  },
  {
    path: '/html',
    name: 'html',
    component: () => import('../views/html/index.vue')
  },
  {
    path: '/ask',
    name: 'ask',
    component: () => import('../views/ask/index.vue'),
    meta: {
      title: '在线答疑-萨恩课堂'
    }
  },
  {
    path: '/askDetail',
    name: 'askDetail',
    component: () => import('../views/askDetail/index.vue')
  },
  {
    path: '/datum',
    name: 'datum',
    component: () => import('../views/datum/index.vue'),
    meta: {
      title: '资料下载-萨恩课堂'
    }
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('../views/information/index.vue'),
    meta: {
      title: '会计资讯-萨恩课堂'
    }
  },
  {
    path: '/informationDetail',
    name: 'informationDetail',
    component: () => import('../views/informationDetail/index.vue')
  },
  {
    path: '/liveList',
    name: 'liveList',
    component: () => import('../views/liveList/index.vue'),
    meta: {
      title: '直播课堂-萨恩课堂'
    }
  },
  {
    path: '/liveRoom',
    name: 'liveRoom',
    component: () => import('../views/liveRoom/index.vue')
  },
  {
    path: '/videoRoom',
    name: 'videoRoom',
    component: () => import('../views/videoRoom/index.vue')
  },
  {
    path: '/payResult',
    name: 'payResult',
    component: () => import('../views/payResult/index.vue'),
    meta: {
      title: '支付结果-萨恩课堂'
    }
  },
  {
    path: '/orderInfo',
    name: 'orderInfo',
    component: () => import('../views/orderInfo/index.vue'),
    meta: {
      title: '订单详情-萨恩课堂'
    }
  },
  {
    path: '/examText',
    name: 'examText',
    component: () => import('../views/examText/index.vue')
  },
  {
    path: '/vipPayPage',
    name: 'vipPayPage',
    component: () => import('../views/vipPayPage/index.vue'),
    meta: {
      title: '会员-萨恩课堂'
    }
  },
  {
    path: '/datumDetail',
    name: 'datumDetail',
    component: () => import('../views/datumDetail/index.vue'),
  },
  {
    path: '/teacherList',
    name: 'teacherList',
    component: () => import('../views/teacherList/index.vue'),
    meta: {
      title: '金牌名师-萨恩课堂'
    }
  },
  {
    path: '/teacherDetail',
    name: 'teacherDetail',
    component: () => import('../views/teacherDetail/index.vue'),
    meta: {
      title: '教师详情-萨恩课堂'
    }
  },
  {
    path: '/practice',
    name: 'practice',
    component: () => import('../views/practice/index.vue'),
    meta: {
      title: '会计真账实训平台-萨恩课堂'
    }
  },
  {
    path: '/myself',
    name: 'myself',
    redirect: "/myself/personal",
    component: () => import('../views/myself/index.vue'),
    children: [
      // {
      //   path: '/',
      //   name: 'personal',
      //   component: () => import('../views/myself/personal.vue'),
      // },
      {
        path: 'myCenter',
        name: 'myCenter',
        component: () => import('../views/myself/myCenter.vue'),
        meta: {
          title: '个人中心-萨恩课堂'
        },
      },
      {
        path: 'learnCenter',
        name: 'learnCenter',
        component: () => import('../views/myself/learnCenter.vue'),
      },
      {
        path: 'myClass',
        name: 'myClass',
        component: () => import('../views/myself/myClass.vue'),
        meta: {
          title: '我的课程-萨恩课堂'
        },
      },
      {
        path: 'liveItem',
        name: 'liveItem',
        component: () => import('../views/myself/liveItem.vue'),
        meta: {
          title: '我的课程-萨恩课堂'
        },
      },
      {
        path: 'myLive',
        name: 'myLive',
        component: () => import('../views/myself/myLive.vue'),
        meta: {
          title: '我的直播-萨恩课堂'
        },
      },
      {
        path: 'myNote',
        name: 'myNote',
        component: () => import('../views/myself/myNote.vue'),
        meta: {
          title: '课程笔记-萨恩课堂'
        },
      },
      {
        path: 'myDaYi',
        name: 'myDaYi',
        component: () => import('../views/myself/myDaYi.vue'),
        meta: {
          title: '课程答疑-萨恩课堂'
        },
      },
      {
        path: 'myDaYiList',
        name: 'myDaYiList',
        component: () => import('../views/myself/myDaYiList.vue'),
        meta: {
          title: '课程答疑-萨恩课堂'
        },
      },
      {
        path: 'myAnswer',
        name: 'myAnswer',
        component: () => import('../views/myself/myAnswer.vue'),
        meta: {
          title: '我的问答-萨恩课堂'
        },
      },
      {
        path: 'myOrder',
        name: 'myOrder',
        component: () => import('../views/myself/myOrder.vue'),
        meta: {
          title: '订单-萨恩课堂'
        },
      },
      {
        path: 'myOrderDetail',
        name: 'myOrderDetail',
        component: () => import('../views/myself/myOrderDetail.vue'),
      },
      {
        path: 'myIntegral',
        name: 'myIntegral',
        component: () => import('../views/myself/myIntegral.vue'),
        meta: {
          title: '订单详情-萨恩课堂'
        },
      },
      {
        path: 'myCoupon',
        name: 'myCoupon',
        component: () => import('../views/myself/myCoupon.vue'),
        meta: {
          title: '优惠券-萨恩课堂'
        },
      },
      {
        path: 'myClassExchange',
        name: 'myClassExchange',
        component: () => import('../views/myself/myClassExchange.vue'),
        meta: {
          title: '课程兑换-萨恩课堂'
        },
      },
      {
        path: 'myInformation',
        name: 'myInformation',
        component: () => import('../views/myself/myInformation.vue'),
        meta: {
          title: '站内消息-萨恩课堂'
        },
      },
      {
        path: 'editUserInfo',
        name: 'editUserInfo',
        component: () => import('../views/myself/editUserInfo.vue'),
        meta: {
          title: '个人资料-萨恩课堂'
        },
      },
      {
        path: 'personal',
        name: 'personal',
        component: () => import('../views/myself/personal.vue'),
      },
      
    ]
  }
]

const router = new VueRouter({
  // mode:'history',
  // mode:'hash',
  routes
})
// 跳转路径时做的判断
router.beforeEach((to, from, next) => {
  //如果用户访问的登录页，直接放行
  store.commit("setpath", { path: to.path })
  window.scrollTo(0, 0)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.path === '/') return next()
  if (to.path == '/liveRoom') {
    var liveSdk = new PolyvLiveSdk({
      channelId: channelId,
      sign: sign, // 频道验证签名
      timestamp: timestamp, // 毫秒级时间戳
      appId: appId, // polyv 后台的appId
      user: {
        userId: userId,
        userName: 'polyv-test',
        pic: '//livestatic.videocc.net/assets/wimages/missing_face.png'
      }
    });
  }
  //从localStorage中获取到保存的token值
  // const tokenStr = window.localStorage.getItem('token')
  // if(!tokenStr){
  //   Message({
  //     message: '您还未进行登录',
  //     type: 'warning'
  //   });
  //   return next('/')
  // }
  next()
})

export default router
