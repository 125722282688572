import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import router from './router'
import '@/scss/index.scss'//引入scss
// import QRCode from 'qrcodejs2'
import QRCode from 'qrcodejs2'
import qs from 'qs';
Vue.prototype.$qs = qs
require('vue-video-player/src/custom-theme.css')
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Resource from 'vue-resource'
Vue.use(Resource)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.http.options.credentials = true
import axios from 'axios'
axios.defaults.withCredentials = true   
import Vuelazyload from 'vue-lazyload';
Vue.use(Vuelazyload)
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
const QRCodeSC = (url) => {
  let qrcode = new QRCode('qrcode', { // qrcode  html为标签id
    width: 254, // 长度
    height: 254, // 宽度
    text: url // 内容
    // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas)
    // background: '#f0f'
    // foreground: '#ff0'
  })
  return qrcode
}
Vue.prototype.$QRCodeSC = QRCodeSC

Vue.prototype.$resetSetItem = function (key, newVal) {
  // 创建一个StorageEvent事件
  var newStorageEvent = document.createEvent('StorageEvent');
  const storage = {
      setItem: function (k, val) {
          // sessionStorage.setItem(k, val);
          localStorage.setItem(k, val);

          // 初始化创建的事件
          newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

          // 派发对象
          window.dispatchEvent(newStorageEvent)
      }
  }
  return storage.setItem(key, newVal);
}